/**
 * Created by gantushig on 4/16/17.
 */

import React, {Component, useEffect, useReducer, useState, useRef} from "react";
import {connect, useSelector} from "react-redux";
import { WithContext as ReactTags } from 'react-tag-input';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import {ActionTypes, Endpoints, EndpointsExpress, TOKEN} from "./Constants";
import axios from "./axiosConfig";
import {failure, fetchAimaks} from "./actions/actions";
import countriesData from "./data/countries1.json"


const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

    return [ htmlElRef, setFocus ]
}

const initialState = {
    sectors: [],
    aimaks:[],
    loading: false,
    exported2:[{id:"AX",name:"Aland Islands"},],
    exported:[],
};

const isEmpty = (obj) => {
    return (obj // 👈 null and undefined check
    && Object.keys(obj).length === 0
    && Object.getPrototypeOf(obj) === Object.prototype)
}
const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_SECTORS_ALL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_SECTORS_ALL_SUCCESS:
            return {
                ...state,
                sectors: action.sectors,
                loading: false
            }
        case ActionTypes.FETCH_AIMAKS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.FETCH_AIMAKS_SUCCESS: {
            return {
                ...state,
                aimaks: action.aimaks,
                loading: false
            }
        }

        case 'INIT_EXPORTED_COUNTRIES':

            let exp = []
            if ( !isEmpty(action.company) ) {
                if (action.company.exp_countries){
                    let str = action.company.exp_countries; //'MN,RU,FR,';
                    //remove the last comma
                    str = str.replace(/,\s*$/, "");

                    let test = str.split(','); //['MN','RU','FR'];
                    test.map(el => {
                        const c = action.countries.find(e => e.id === el)
                        exp = [...exp, c]
                    })
                }
            }

            // check tag
            return {
                ...state,
                exported: exp,
            }
        case 'ADD_COUNTRY':

            return {
                ...state,
                exported: [...state.exported, action.tag],
            }
        case 'DELETE_COUNTRY':

            return {
                ...state,
                exported: state.exported.filter((tag, index) => index !== action.i)

            }
        default:
            return state;
    }
};



const fetchSectorsAllRequest = () => {
    return {
        type: ActionTypes.LOAD_SECTORS_ALL_REQUEST
    }
};

const fetchSectorsAllSuccess = (sectors) => {
    return {
        type: ActionTypes.LOAD_SECTORS_ALL_SUCCESS,
        sectors
    }
};

const fetchSectorsAll = (dispatch, token) => {

    dispatch(fetchSectorsAllRequest())
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.SECTORS}/all`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchSectorsAllSuccess(response.data.items ))
        })
        .catch(error => {

            // if (error.response === undefined) {
            //     dispatch(failure("Error", error.message));
            // } else {
            //     dispatch(failure(error.response.data.error, error.response.data.message));
            // }
            throw(error);

        });
}

const addCountry =(tag) => {
    return {
        type: 'ADD_COUNTRY',
        tag
    }
}

const initCountries =(countries, company) => {
    return {
        type: 'INIT_EXPORTED_COUNTRIES',
        countries,
        company
    }
}

const deleteCountry =(i) => {
    return {
        type: 'DELETE_COUNTRY',
        i
    }

}

const CompanyEditComponent = ({
                                  company, isCreate, onChangeJoined, onChangeEstablished, onChangeField, onChangeCheckField, onSave, onCancel}) => {

    const [inputRef, setInputFocus] = useFocus()

    let token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {sectors, aimaks, exported} = state;

    useEffect(() => {
        console.log('useEffect called');
        fetchSectorsAll(dispatch, token)
    }, []);


    useEffect(() => {
        fetchAimaks(dispatch);
    }, []);



    const KeyCodes = {
        comma: 188,
        enter: [10, 13],
    };

    const delimiters = [...KeyCodes.enter, KeyCodes.comma];


    const suggestions = countriesData; //saimaks.countries_arr;
    useEffect(() => {

        dispatch(initCountries(suggestions, company))
        setInputFocus();
    }, [company]);

    const handleSave = (e) => {
        let ex = "";
        exported.map(m=>{
            ex += m.id;
            ex += ',';
        })
        company.exp_countries = ex;
        onSave()
    };

    const districtOptions = () => {
        let items = [];
        if (company.aimak) {
            let ra = aimaks.filter(a=>a.id === company.aimak);
            if (ra.length>0) {
                ra[0].units.map(item=>{
                    items.push(<option key={item.id} value={item.id}>{item.name}</option>);
                })

            }
        }
        return items;
    };
    const aimaksOptions = () => {
        let items = [];
        aimaks.map(item=>{
            items.push(<option key={item.id} value={item.id}>{item.name}</option>);
        })
        return items;
    };
    const sectorsOptions = () => {
        let items = [];
        for (let key in sectors) {
            let s = sectors[key]
            items.push(<option key={s.id} value={s.id}>{s.name}</option>);
        }
        return items;
    };

    const handleDelete = (i)=> {
        dispatch(deleteCountry(i));
    }

    const handleAddition = (tag)=> {

        // check tag
        if (suggestions.some(e => e.id === tag.id)) {
            dispatch(addCountry(tag));
        }
    }
    return (
        <div style={{background: 'white'}}>

            <div className="field">
                <label className="label">Хувь хүн, хуулийн этгээдийн нэр, хэлбэр, төрөл:</label>
                <div className="control has-icons-left">
                    <input  className="input"  name="name" type="text" value={company.name}
                           onChange={(e) => onChangeField(e)} placeholder="Нэр оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Нэр Англиар:</label>
                <div className="control has-icons-left">
                    <input className="input"  name="name_english" type="text" value={company.name_english}
                           onChange={(e) => onChangeField(e)} placeholder="Нэр оруулах (Англиар)"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Улсын бүртгэлийн дугаар</label>
                <div className="control has-icons-left">
                    <input className="input" name="state_reg_number" type="text" value={company.state_reg_number}
                           onChange={(e) => onChangeField(e)} placeholder="Улсын бүртгэлийн дугаар оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Регистрийн дугаар</label>
                <div className="control has-icons-left">
                    <input className="input" name="registration_number" type="text" disabled={!isCreate} value={company.registration_number}
                           onChange={(e) => onChangeField(e)} placeholder="Регистрийн дугаар оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Байгуулагдсан огноо</label>
                <div className="control has-icons-left">
                    <DatePicker utc = {0} className="input" name="established"   selected = {company.established}
                                 onChange={onChangeEstablished} placeholderText="Байгуулагдсан огноо оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Элссэн огноо</label>
                <div className="control has-icons-left">
                    <DatePicker  className="input"  name="joined"  selected = {company.joined}
                                onChange={onChangeJoined} placeholderText="Элссэн огноо оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Аймаг/Хот</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select  name="aimak"  value={company.aimak} onChange={(e) => onChangeField(e)}>
                            <option value=""></option>
                            {aimaksOptions()}
                        </select>
                    </div>
                </div>
            </div>
            <div className="field">
                <label className="label">Сум / Дүүрэг</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select  name="district"  value={company.district} onChange={(e) => onChangeField(e)}>
                            <option value=""></option>
                            {districtOptions()}
                        </select>
                    </div>
                </div>
            </div>
            <div className="field">
                <label className="label">Баг / Хороо</label>
                <div className="control has-icons-left">
                    <input className="input" name="soum"  type="text" value={company.soum}
                           onChange={(e) => onChangeField(e)} placeholder="Сум оруулах"/>
                </div>
            </div>

            <div className="field">
                <label className="label">Гудамж/Тоот</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" name="address"  value={company.address}
                           onChange={(e) => onChangeField(e)} placeholder="Гудамж/Тоот оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Утас</label>
                <div className="control has-icons-left">
                    <input className="input" type="text" name="phone"  value={company.phone}
                           onChange={(e) => onChangeField(e)} placeholder="Утас оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Шуудангийн хайрцаг</label>
                <div className="control has-icons-left">
                    <input className="input" name="postal_address"  type="text" value={company.postal_address}
                           onChange={(e) => onChangeField(e)} placeholder="Шуудангийн хаяг оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Мэйл</label>
                <div className="control has-icons-left">
                    <input className="input"  name="email" type="text" value={company.email}
                           onChange={(e) => onChangeField(e)} placeholder="Мэйл оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Сошиал хаяг</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  name="social" value={company.social}
                           onChange={(e) => onChangeField(e)} placeholder="Сошиал хаяг оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Вебхуудас</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  name="web" value={company.web}
                           onChange={(e) => onChangeField(e)} placeholder="Вебхуудас оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Зип код</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  name="zipcode" value={company.zipcode}
                           onChange={(e) => onChangeField(e)} placeholder="Zip code оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Шуудангийн код</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  name="postal_code" value={company.postal_code}
                           onChange={(e) => onChangeField(e)} placeholder="Шуудангийн код оруулах"/>
                </div>
            </div>
            <div className="field">
                <label className="label">Gepir</label>
                <div className="control has-icons-left">
                    <input className="checkbox" type="checkbox"  name="is_gepir" checked={company.is_gepir}
                           onChange={(e) => onChangeCheckField(e)}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Certificate</label>
                <div className="control has-icons-left">
                    <input className="checkbox" type="checkbox"  name="is_certificate"  checked={company.is_certificate}
                           onChange={(e) => onChangeCheckField(e)}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Экспорт хийдэг эсэх</label>
                <div className="control has-icons-left">
                    <input className="checkbox" type="checkbox"  name="is_exported"  checked={company.is_exported}
                           onChange={(e) => onChangeCheckField(e)}/>
                </div>
            </div>
            <div className="field">
                <label className="label">Тийм бол экспорт хийж буй улсын нэр/ Үгүй бол хийхээр төлөвлөж байгаа улс /gs1mn/</label>
                <ReactTags
                    classNames={{
                        tags: '',
                        tagInput: 'border text-white',
                        tagInputField: 'border bg-white text-black p-2 w-1/2',
                        selected: 'border bg-grey-500 p-2',
                        tag: 'border bg-gray-500 p-2 text-white p-2',
                        remove: ' p-2',
                        suggestions: 'border  bg-gray-200 p-2 text-gray-800 ',
                        activeSuggestion: 'border bg-gray-600 p-2 text-white'
                    }}
                    tags={exported}
                           suggestions={suggestions}
                    placeholder='Улсын нэрээ сонгоод Enter дарна уу!'
                           labelField={'name'}
                           handleDelete={handleDelete}
                           handleAddition={handleAddition}
                           delimiters={delimiters} />
            </div>

            <div className="field">
                <label className="label">Тийм бол экспорт хийж буй улсын нэр/ Үгүй бол хийхээр төлөвлөж байгаа улс /company/</label>
                <div className="control has-icons-left">
                    <input className="input" type="text"  name="is_exported_countries" value={company.is_exported_countries}
                           onChange={(e) => onChangeField(e)} placeholder="Улс оруулах"/>
                </div>
            </div>

            <div className="field">
                <label className="label">Үйл ажиллагааны чиглэл</label>
                <div className="control has-icons-left">
                    <div className="select">
                        <select value={company.sector}  name="sector" onChange={(e) => onChangeField(e)}>
                            <option value=""></option>
                            {sectorsOptions()}
                        </select>
                    </div>
                </div>
            </div>

            <div class="buttons is-right" style={{paddingTop: '10px'}}>
                <button className="button" type="primary" onClick={() => onCancel()}>Буцах</button>
                <button className="button is-success" type="primary"
                        onClick={() => handleSave()}>                    Хадгалах                </button>

            </div>
        </div>
    )

}


export default CompanyEditComponent