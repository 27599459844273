import {Redirect} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import classnames from "classnames";

import React, {useEffect, useReducer, useState} from "react";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import history from "./history"
import {ActionTypes, Endpoints, Menu, Page, PageTitle, TOKEN} from './Constants';
import dayjs from "dayjs";
import dayjsPluginUTC from 'dayjs-plugin-utc'
import {
    fetchCompanyApplications
} from "./actions/actions";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import {menuClicked} from "./actions/actions";
import NullUtils from "./utils/nullUtils";
dayjs.extend(dayjsPluginUTC)


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    companies: [],
    meta: {
        totalPages: 0,
        totalElements: 0,
    },
    loading: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_COMPANY_APPLICATIONS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.LOAD_COMPANY_APPLICATIONS_SUCCESS:
            return {
                ...state,
                companies: action.companies,
                meta: action.meta,
                loading: false
            }
        default:
            return state;
    }
};


const CompanyApplications = ({
                                 location: {search}
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    let token = localStorage.getItem(TOKEN) || null;
    const {companies, meta, loading

    } = state;

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let pagesize = parsed[Page.PAGESIZE];
    if (page === undefined) {
        page = "0";
    }
    if (pagesize === undefined) {
        pagesize = "25";
    }

    useEffect(() => {
        dispatch(menuClicked(Menu.COMPANY_APPLS, PageTitle.COMPANY_APPLS));
    }, []);

    useEffect(() => {
        fetchCompanyApplications(dispatch, token,  page, pagesize);
     }, [page]);


    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;
        history.push("?" + qs.stringify(parsed))
    };


    const getStatus = (stat) => {
        if (stat === "SENT") {
            return "ИРСЭН";
        } else if (stat === "ACTIVATED") {
            return "ИДЭВХТЭЙ";
        }
    }

    const getDevice = (dev) => {
        if (NullUtils(dev)) {
            return "WEB";
        } else  {
            return "APP";
        }
    }

    let c = 0;
    const items = companies.map(
        (item) => {
            c++;
            let c1 = Number(page) * Number(pagesize) + c;
            return (
                <tr key={item.id}>
                    <td><Link to={`/company-appl/${item.id}`}>{c1}</Link></td>
                    <td><Link to={`/company-appl/${item.id}`}>{item.name}</Link></td>
                    <td>
                        <Link to={`/company-appl/${item.id}`}>
                            {item.registration_number}
                        </Link>
                    </td>
                    <td>{item.created && dayjs.utc(item.created).format("MMM/DD/YYYY")}</td>
                    <td>{getStatus(item.status)}</td>
                    <td>{getDevice(item.device)}</td>
                </tr>
            )
        });


    return (
        <div className="inner-container">
            <h5 style={{marginBottom: "10px"}}><strong>Нийт: {meta.totalElements}</strong></h5>
            <ClipLoader color="#ffffff" loading={state.loading} css={override} size={150}/>
            <table className="table is-fullwidth">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Компани</th>
                    <th>Регистр</th>
                    <th>Илгээсэн огноо</th>
                    <th>Статус</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={Number(page)}
                               breakLabel={<a href="">...</a>}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={state.meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               containerClassName={"pagination-list"}
                               forcePage={Number(page)}
                />
            </nav>
            <br/><br/>

        </div>
    )

}


export default CompanyApplications


